<template>
  <el-dialog
    :visible="true"
    v-bind="$props"
    class="confirm-dialog"
    @update:visible="$emit('close')"
  >
    <div v-if="$slots.title" slot="title">
      <slot name="title" />
    </div>

    <slot>{{ content }}</slot>

    <template slot="footer">
      <slot name="footer">
        <template v-if="!hideButtons">
          <template v-if="!isSaving">
            <el-button :class="cancelClass" @click="$emit('close')">
              <slot name="cancel-text">{{ cancelText }}</slot>
            </el-button>
            <el-button
              v-if="!disabled"
              :class="confirmClass"
              @click="$emit('confirm')"
            >
              <slot name="confirm-text">{{ confirmText }}</slot>
            </el-button>
          </template>

          <loading-button v-else />
        </template>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import LoadingButton from '@/components/Core/Loading/LoadingButton';

export default {
  components: {
    LoadingButton
  },
  props: {
    ...Dialog.props,
    content: {
      type: String,
      default: 'Are you sure you want to proceed?'
    },
    disabled: Boolean,
    hideButtons: Boolean,
    appendToBody: {
      type: Boolean,
      default: true
    },
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: Boolean,
    title: {
      type: String,
      default: 'Are You Sure?'
    },
    isSaving: Boolean,
    onSave: { type: Function, default: null },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmClass: {
      type: String,
      default: 'button-blue'
    },
    cancelClass: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: null
    }
  }
};
</script>
