<template>
  <div
    ref="dropZone"
    class="upload-box"
    :class="{
      'has-files': hasFiles,
      'is-disabled': disabled,
      'cursor-not-allowed': !hasFiles && disabled
    }"
    :style="style"
    @dragenter.stop.prevent="onDragEnter"
    @dragover.stop.prevent="onDragEnter"
    @dragleave.stop.prevent="onDragLeave"
    @drop.stop.prevent="onDrop"
  >
    <input
      ref="upload"
      type="file"
      hidden="hidden"
      :multiple="multiple"
      :accept="accept"
      @change="uploadFiles($event.target.files)"
    />
    <div
      class="full-height el-upload-container has-content-centered"
      @click="!isDisabled && $refs.upload.click()"
    >
      <transition name="fade" mode="out-in">
        <div
          v-if="!hasFiles"
          :key="'placeholder'"
          class="upload-box-contents full-height has-content-centered"
        >
          <slot name="placeholder">
            <div v-if="isDisabled" class="cursor-disabled">
              <div class="placeholder-icon text-dark-silver">
                <icon :icon="upload" class="icon-lg" />
              </div>
              <div class="placeholder-text text-dark-silver">
                Uploading is Disabled
              </div>
            </div>
            <template v-else>
              <div class="placeholder-icon text-dark-silver">
                <icon :icon="upload" class="icon-lg" />
              </div>
              <div class="placeholder-text text-dark-silver">
                <div>Click to upload</div>
                <div>(or drag and drop)</div>
              </div>
            </template>
          </slot>
        </div>

        <div v-else :key="'file-list'" class="uploaded-file-list">
          <transition-group
            tag="div"
            :name="transitionName"
            mode="in-out"
            class="relative flex flex-wrap items-stretch animate-position"
            :class="computedItemListClass"
          >
            <div
              v-for="file in fileList"
              :key="`file-${file.uid || file.id}`"
              class="block p-3"
              :class="itemClass"
            >
              <file-item
                :file="wrapFile(file)"
                v-bind="fileItemProps"
                :is-primary="isPrimary(file)"
                @view="handlePreview"
                @remove="handleRemove"
                @primary="$emit('primary', $event)"
                @download="$emit('download')"
              />
            </div>

            <div
              v-if="canUploadMore && !uploading"
              :key="'new-file'"
              class="block p-3"
            >
              <div
                class="uploader-item has-content-centered border"
                :style="uploaderItemStyle"
                :class="fileItem && fileItem.class"
              >
                <icon :icon="upload" class="icon-lg text-dark-silver" />
              </div>
            </div>
          </transition-group>
        </div>
      </transition>
    </div>

    <el-dialog
      v-if="previewFile"
      :visible="true"
      :append-to-body="true"
      class="full-content"
      @close="previewFile = false"
    >
      <div class="pad-lg">
        <img :src="previewFile" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cookie } from '@/utils/cookie';
import { FileUpload } from '@/utils/fileUpload.js';
import { previewImg } from '@/utils/helpers';
import { upload } from '@/vendor/icons';
import { cloneDeep, filter, isEqualWith } from 'lodash';

import FileItem from './FileItem';

export default {
  components: {
    FileItem
  },
  props: {
    accept: { type: String, default: null },
    enablePreview: Boolean,
    itemClass: {
      type: String,
      default: 'is-narrow'
    },
    itemListClass: {
      type: String,
      default: 'is-multiline is-vcenter is-stretch'
    },
    transitionName: {
      type: String,
      default: 'fadeLeft'
    },
    primaryFile: { type: Object, default: null },
    allowPrimary: Boolean,
    canDelete: {
      type: Boolean,
      default: true
    },
    uploading: Boolean,
    showFilename: Boolean,
    value: {
      type: [Array, Object],
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    download: Boolean,
    size: { type: String, default: null },
    fileItem: { type: Object, default: null }
  },

  data() {
    return {
      // Our component's version of the fileList (some parents may not sync the value immediately on input)
      fileList: [],
      previewFile: false,
      lastUpdate: 0,

      // Icons
      upload
    };
  },

  computed: {
    fileListValue() {
      return this.multiple ? this.fileList : this.fileList[0];
    },

    computedItemListClass() {
      return this.itemListClass + (this.multiple ? '' : ' justify-center');
    },
    fileItemProps() {
      return {
        enablePreview: this.enablePreview,
        allowPrimary: !this.disabled && this.allowPrimary,
        showFilename: this.showFilename,
        canDelete: !this.disabled && this.canDelete,
        canDownload: this.download,
        ...this.fileItem
      };
    },

    headers() {
      return {
        'X-XSRF-TOKEN': cookie('XSRF-TOKEN')
      };
    },

    canUploadMore() {
      return !this.disabled && (this.multiple || this.fileList.length < 1);
    },

    hasFiles() {
      return this.fileList.length > 0;
    },

    style() {
      return {
        height: this.size
      };
    },

    uploaderItemStyle() {
      return {
        width: this.fileItemProps.width || '8em',
        height: this.fileItemProps.height || '6em'
      };
    },

    isDisabled() {
      if (this.disabled) {
        return true;
      }

      if (!this.multiple) {
        return this.fileList.length >= 1;
      }

      return false;
    }
  },

  watch: {
    value() {
      this.mapValueToFileList();
    }
  },

  mounted() {
    this.mapValueToFileList();
  },

  methods: {
    mapValueToFileList() {
      let value = cloneDeep(this.value);
      value = Array.isArray(value) ? value : [value];

      // Make sure we ignore any empty / null files
      value = value.filter(v => !!v);

      if (
        !isEqualWith(value, this.fileList, (a, b) => {
          // If the value is an array, return undefined so the method will loop through the array
          if (Array.isArray(a)) {
            return undefined;
          }

          return (
            a.id === b.id &&
            a.uid === b.uid &&
            a.name === b.name &&
            a.size === b.size
          );
        })
      ) {
        this.fileList = value;
      }
    },
    isPrimary(file) {
      return this.primaryFile ? this.primaryFile.id === file.id : false;
    },

    uploadFiles(files) {
      this.fileList = [...this.fileList, ...files];
      this.$emit('update:uploading', true);
      new FileUpload(files, 'file-upload')
        .onProgress(({ file }) => {
          this.updateFileInList(file);
        })
        .onComplete(({ file, uploadedFile }) => {
          this.updateFileInList(file, uploadedFile);
        })
        .onAllComplete(() => {
          this.$emit('input', this.fileListValue);
          this.$emit('change', this.fileListValue);
          this.$emit('complete', this.fileListValue);
          this.$emit('update:uploading', false);
        })
        .upload();
    },

    updateFileInList(file, replace = null) {
      const index = this.fileList.findIndex(f => f.id === file.id);
      if (index !== -1) {
        replace = replace || file;
        const tmp = [...this.fileList];
        replace.uid = file.uid || file.id;
        tmp.splice(index, 1, replace);
        this.fileList = tmp;
      }
    },

    handlePreview(file) {
      if (file.url) {
        this.previewFile = file.url;
      } else {
        previewImg(file.raw).then(reader => {
          this.previewFile = reader.result;
        });
      }
    },

    handleRemove(file) {
      this.fileList = filter(this.fileList, f => f.id !== file.id);
      this.$emit('input', this.fileListValue);
      this.$emit('change', this.fileListValue);
      this.$emit('complete', this.fileListValue);
    },

    wrapFile(file) {
      return FileUpload.wrapFile(file);
    },

    onDragEnter() {
      this.$refs.dropZone.className += ' is-dragging-over';
    },

    onDragLeave() {
      if (this.$refs.dropZone) {
        this.$refs.dropZone.className = this.$refs.dropZone.className.replace(
          ' is-dragging-over',
          ''
        );
      }
    },

    onDrop(e) {
      this.onDragLeave(e);
      this.uploadFiles(e.dataTransfer.files);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.upload-box {
  border: 1px dashed $color-gray;
  border-radius: 5px;
  min-height: 12em;
  display: flex;
  flex-direction: column;

  &.is-dragging-over {
    border-color: $color-blue;
  }

  .el-upload-container /deep/ {
    .el-upload-dragger {
      display: flex;
      flex-direction: column;
      border: none;
      height: 100%;
      width: 100%;
      flex-grow: 1;
    }

    .el-upload--text {
      display: flex;
      height: 100%;
      width: 100%;
      flex-grow: 1;
      flex-direction: column;
    }
  }

  .uploaded-file-list {
    padding: 1em;
  }

  &.is-disabled {
    border-color: transparent;

    /deep/ .el-upload-dragger {
      cursor: not-allowed;
    }
  }
}
</style>
