<template>
  <div class="file-item overflow-hidden" :style="style" @click.stop>
    <div class="image">
      <div
        class="flex items-center h-full"
        :class="{ 'justify-center': !alignLeft }"
      >
        <template v-if="thumbnailSrc">
          <img ref="image" :src="thumbnailSrc" alt="" />
        </template>
        <div v-else-if="videoSrc" class="video">
          <icon :icon="play2" class="play -ml-2 -mt-2 text-4xl text-white" />
          <video :src="videoSrc"></video>
        </div>
        <div v-else-if="thumbnailIcon">
          <icon :icon="thumbnailIcon" class="text-6xl" />
        </div>
      </div>
      <div v-if="displayFilename && showFilename" class="filename">
        {{ file.name || file.filename }}
      </div>
    </div>

    <transition name="fade">
      <div v-if="isUploading" class="progress-overlay is-overlay overlay-white">
        <transition name="fade" mode="out-in">
          <div
            :key="serverPercentage ? 'server-progress' : 'upload-progress'"
            class="progress-info"
          >
            <div
              ref="progressBar"
              class="progress-bar"
              :style="{ height: progressBarWidth + 'px' }"
            >
              <el-progress
                v-if="showProgressBar"
                class="progress-bar-component"
                type="circle"
                :color="serverPercentage ? Color.green : Color.blue"
                :percentage="serverPercentage || percentage || 0"
                :width="progressBarWidth"
              />
            </div>

            <div class="progress-state">
              <template v-if="serverPercentage">Processing...</template>
              <template v-else> Uploading...</template>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <transition name="fade">
      <confirm-dialog
        v-if="showConfirmRemove"
        title="Remove File"
        content="Are you sure you want to remove this file?"
        confirm-text="Remove"
        confirm-class="button-red"
        width="23em"
        @confirm="$emit('remove', file) || (showConfirmRemove = false)"
        @close="showConfirmRemove = false"
      />
    </transition>

    <div
      v-if="!showConfirmRemove"
      class="opacity-0 is-overlay has-content-centered"
      :class="{ 'hover:opacity-75': !group }"
      @click="doFileAction"
    >
      <div class="action-bar is-position-top pad-vert-xxs pad-horiz-xs">
        <div class="flex">
          <div class="block p-3">
            <a v-if="hasPreview" class="text-white hover:text-green">
              <icon :icon="zoomIn" />
            </a>
          </div>

          <div class="block flex-1 p-3">
            <div class="flex justify-end m-0">
              <div v-if="canDelete" class="block">
                <a class="remove" @click.stop="showConfirmRemove = true">
                  <icon :icon="trashEmpty" class="text-white hover:text-red" />
                </a>
              </div>

              <div v-if="canDownload" class="block">
                <a
                  class="text-white hover:text-green pad-left-sm"
                  @click.stop="downloadFile(file.url)"
                >
                  <icon :icon="downloadIcon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="allowPrimary" class="set-primary margin-top z-10">
        <el-button
          class="button-clear-white has-transition"
          size="mini"
          round
          @click.stop="$emit('primary', file)"
        >
          <icon v-if="isPrimary" :icon="ok" />
          <span v-else>Set Primary</span>
        </el-button>
      </div>
    </div>

    <confirm-dialog
      v-if="showPreviewDialog"
      class="full-content"
      cancel-text="Close"
      :title="''"
      disabled
      width="100em"
      @close="showPreviewDialog = false"
    >
      <div class="p-5">
        <div>
          <video
            v-if="videoSrc"
            :src="videoSrc"
            controls
            class="max-w-full max-h-70vh"
          />
          <img
            v-else-if="imageSrc"
            alt="Preview Image"
            :src="imageSrc"
            class="max-w-full max-h-70vh"
          />
          <el-alert v-else type="warning" title="">
            Unable to preview this file type
          </el-alert>
        </div>

        <div v-if="canDownload" class="w-full mt-5">
          <template v-if="!isDownloading">
            <h3>Download</h3>

            <div class="mt-3 text-dark-silver text-sm">
              {{ file.name || file.filename }}
            </div>

            <div class="mt-3 flex justify-between">
              <div class="flex-grow">
                <el-button
                  class="button-blue w-full"
                  @click="downloadFile(file.url)"
                >
                  Original {{ fileExt }} ({{ file.size | shortSize }})
                </el-button>
              </div>
              <div v-if="compressedImage" class="ml-3 flex-grow">
                <el-button
                  class="button-green w-full"
                  @click="downloadFile(compressedImage.url)"
                >
                  Optimized {{ compressedExt }} ({{
                    compressedImage.size | shortSize
                  }})
                </el-button>
              </div>
            </div>
            <el-tooltip v-if="compressedImage" effect="light" class="mt-3">
              <template #content>
                <div class="text-base w-100">
                  <div>
                    The <b>Original</b> is the file that was originally
                    uploaded. This is likely the highest quality asset. Use this
                    asset when you need to print something in high resolution
                    (ie: for print in a newspaper or magazine).
                  </div>
                  <div class="mt-3">
                    The <b>Optimized</b> asset is a compressed and transcoded
                    version that is optimized for display on a web page. Use
                    this asset when you want to manually place it on digital
                    websites, social media, or some other form of media that
                    appears on a desktop, tablet, or mobile device
                  </div>
                </div>
              </template>
              <a>What is the difference between Original and Optimized?</a>
            </el-tooltip>
          </template>
          <loading-button v-else class="w-full" />
        </div>
      </div>
    </confirm-dialog>
  </div>
</template>

<script>
import { download, optimizedFile, previewImg } from '@/utils/helpers';
import Color from '@/tailwind/colors';

import {
  cancel,
  docText,
  download as downloadIcon,
  filePdf,
  ok,
  play2,
  trashEmpty,
  zoomIn
} from '@/vendor/icons';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import moment from 'moment';

export default {
  components: { ConfirmDialog },
  props: {
    isPrimary: Boolean,
    allowPrimary: Boolean,
    enablePreview: Boolean,
    canDelete: Boolean,
    alignLeft: Boolean,
    canDownload: {
      type: Boolean,
      default: true
    },
    file: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '8em'
    },
    height: {
      type: String,
      default: '6em'
    },
    group: Boolean,
    showFilename: {
      type: Boolean,
      default: true
    },
    large: Boolean
  },

  data() {
    return {
      isDownloading: false,
      serverStartTime: null,
      serverPercentage: null,
      uploadedSrc: null,
      showConfirmRemove: false,
      showPreviewDialog: false,
      showProgressBar: false,

      // Icons
      cancel,
      docText,
      downloadIcon,
      filePdf,
      ok,
      play2,
      trashEmpty,
      zoomIn,

      // consts
      Color
    };
  },

  computed: {
    mime() {
      return this.file.mime || (this.file.raw ? this.file.raw.type : null);
    },

    isVideo() {
      switch (this.mime) {
        case 'video/3gpp':
        case 'video/x-m4v':
        case 'video/mp2t':
        case 'video/mp4':
        case 'video/mpeg':
        case 'video/ogg':
        case 'video/webm':
          return true;
      }
      return false;
    },

    isImage() {
      switch (this.mime) {
        case 'image/vnd.adobe.photoshop':
        case 'image/x-icon':
        case 'image/bmp':
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
        case 'image/svg':
        case 'image/tiff':
        case 'image/webp':
        case 'image/x-eps':
          return true;
      }

      return false;
    },

    thumbnailIcon() {
      switch (this.mime) {
        case 'application/pdf':
          return filePdf;

        default:
          return docText;
      }
    },

    thumbImage() {
      return this.file.transcodes && this.file.transcodes.thumb;
    },

    compressedImage() {
      return optimizedFile(this.file, true);
    },

    fileExt() {
      return this.file.url.split('.').pop();
    },

    compressedExt() {
      return this.compressedImage && this.compressedImage.url.split('.').pop();
    },

    thumbnailSrc() {
      if (this.uploadedSrc && this.isImage) {
        return this.uploadedSrc;
      }

      if (this.thumbImage) {
        return this.thumbImage.url;
      }

      // If there is an ID set, then this file is stored on the platform
      if (this.file.id && this.isImage) {
        return this.file.url;
      }

      return null;
    },

    imageSrc() {
      if (!this.isImage && !this.compressedImage) {
        return null;
      }

      if (this.compressedImage) {
        return this.compressedImage.url;
      }

      // If there is an ID set, then this file is stored on the platform
      if (this.file.id) {
        return this.file.url;
      }

      return null;
    },

    videoSrc() {
      if (this.uploadedSrc && this.isVideo) {
        return this.uploadedSrc;
      }

      if (this.file.transcodes) {
        if (this.file.transcodes.mp4) {
          return this.file.transcodes.mp4.url;
        }
      }

      // If there is an ID set, then this file is stored on the platform
      if (this.file.id && this.isVideo) {
        return this.file.url;
      }

      return null;
    },

    hasPreview() {
      return this.enablePreview && (this.thumbnailSrc || this.videoSrc);
    },

    isUploading() {
      return this.file.status === 'uploading';
    },

    percentage() {
      if (this.file.percentage) {
        return Math.min(Math.round(this.file.percentage), 100);
      }

      return this.isUploading ? 100 : null;
    },

    style() {
      return {
        width: this.width,
        height: this.height
      };
    },

    displayFilename() {
      // If the parent has not specified to show the file name, then check if this has a thumbnail to preview,
      // otherwise we want to show the filename
      if (!this.thumbnailSrc && !this.videoSrc) {
        return true;
      }

      return this.showFilename;
    },

    progressBarWidth() {
      if (this.showProgressBar && this.$refs.progressBar) {
        return this.calcProgressBarWidth();
      } else {
        return 64;
      }
    }
  },

  watch: {
    percentage() {
      if (this.percentage === 100) {
        this.calcServerPercentage();
      }

      // If the width has changed since we started, refresh the progress bar
      if (this.progressBarWidth !== this.calcProgressBarWidth) {
        this.resetProgressBar();
      }
    }
  },

  mounted() {
    // If there is no URL, this file must have just been uploaded
    // so lets load an upload file preview
    if (this.file.raw) {
      previewImg(this.file.raw).then(reader => {
        this.uploadedSrc = reader.result;
      });
    }

    // Just in case we're already at 100%
    this.calcServerPercentage();

    // Dynamically set progress bar width
    this.resetProgressBar();
  },

  methods: {
    calcProgressBarWidth() {
      if (this.$refs.progressBar) {
        let rect = this.$refs.progressBar.getBoundingClientRect();
        if (rect) {
          return Math.min(rect.width, rect.height) || 64;
        }
      }

      // Set a reasonable default
      return 64;
    },
    resetProgressBar() {
      this.showProgressBar = false;

      this.$nextTick(() => {
        if (this.$el) {
          this.showProgressBar = true;
        } else {
          setTimeout(this.resetProgressBar, 100);
        }
      });
    },
    calcServerPercentage() {
      if (this.isUploading && this.percentage === 100) {
        if (!this.serverStartTime) {
          this.serverStartTime = moment();
        }

        let elapsed = moment().diff(this.serverStartTime, 'seconds');

        this.serverPercentage = Math.round(
          Math.min(99, (100 * elapsed) / (this.file.size / 1000000))
        );

        // XXX: A little hack to make our file list react to time based changes
        setTimeout(this.calcServerPercentage, 500);
      } else {
        this.serverStartTime = null;
        this.serverPercentage = null;
      }
    },

    doFileAction() {
      this.$emit('click', this.file);

      // Show a preview / download dialog if preview is available, otherwise just download the file if possible
      if (this.hasPreview) {
        this.showPreviewDialog = true;
      } else if (this.canDownload) {
        this.downloadFile(this.file.url);
      } else {
        this.$emit('view', this.file);
      }
    },

    async downloadFile(fileUrl) {
      this.$emit('download');

      this.isDownloading = true;

      await download(fileUrl);

      setTimeout(() => {
        this.isDownloading = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

.file-item {
  position: relative;
  display: flex;

  .image {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .filename {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    font-size: 0.9em;
    padding: 0 0.4em;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .video {
    position: relative;
    height: 6em;

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    video {
      height: 6em;
    }
  }
}

.progress-overlay {
  .progress-info {
    display: flex;
    flex-direction: column;
    height: 100%;

    .progress-bar {
      flex-grow: 1;

      /deep/ .el-progress-circle {
        margin: auto;
      }
    }
  }

  .progress-state {
    flex-shrink: 0;
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    color: $color-dark-silver;
  }
}
</style>
