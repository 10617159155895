import uuid from 'uuid';
import Vue from 'vue';

export class FileUpload {
  /**
   * @param {File|File[]} files
   * @param directory
   */
  constructor(files, directory = 'mva-verifications') {
    if (!Array.isArray(files) && !(files instanceof FileList)) {
      files = [files];
    }
    this.files = files;
    this.fileUploads = [];
    this.onErrorCb = null;
    this.onProgressCb = null;
    this.onCompleteCb = null;
    this.onAllCompleteCb = null;
    this.directory = directory;
    this.completedPromise = null;
    this.prepare();
  }

  /**
   * Prepares all files for upload and provides an id and blobUrl for each file
   */
  prepare() {
    // Prepare required attributes
    for (let file of this.files) {
      if (!(file instanceof File)) {
        throw Error(
          'FileUpload constructor requires a File object or a list of File objects'
        );
      }

      file.id = uuid();
      file.blobUrl = URL.createObjectURL(file);

      // Prepare FormData
      const formData = new FormData();
      formData.append('file', file);

      this.fileUploads.push({
        file,
        xhr: null, // NOTE: The XHR will be setup asynchronously right before sending file uploads
        formData,
        isComplete: false
      });
    }
  }

  /**
   * Callback for when all files have been uploaded
   */
  onAllComplete(cb) {
    this.onAllCompleteCb = cb;
    return this;
  }

  /**
   * Callback fired once for each file upon successful completion of upload
   * @param cb
   * @returns {FileUpload}
   */
  onComplete(cb) {
    this.onCompleteCb = cb;
    return this;
  }

  /**
   * Callback fired each time there is an upload progress update for a file
   * @param cb
   * @returns {FileUpload}
   */
  onProgress(cb) {
    this.onProgressCb = cb;
    return this;
  }

  /**
   * Callback fired when an error occurs during upload
   * @param cb
   * @returns {FileUpload}
   */
  onError(cb) {
    this.onErrorCb = cb;
    return this;
  }

  /**
   * Handles the error events / fires the callback if it is set
   * @param e
   * @param file
   * @param error
   */
  errorHandler(e, file, error) {
    if (this.onErrorCb) {
      this.onErrorCb({ e, file, error });
    }
  }

  /**
   * Fires the progress callback
   * @param fileUpload
   * @param progress
   */
  fireProgressCallback(fileUpload, progress) {
    fileUpload.file.progress = progress;
    this.onProgressCb &&
      this.onProgressCb({
        file: FileUpload.wrapFile(fileUpload.file),
        progress
      });
  }

  /**
   * Fires the complete callback
   * @param fileUpload
   * @param uploadedFile
   */
  fireCompleteCallback(fileUpload, uploadedFile) {
    fileUpload.isComplete = true;
    fileUpload.file.progress = 1;
    this.onCompleteCb &&
      this.onCompleteCb({
        file: FileUpload.wrapFile(fileUpload.file),
        uploadedFile
      });
  }

  /**
   * Check if all files have been uploaded and call the callback if they have
   */
  checkAllComplete() {
    if (this.fileUploads.every(fileUpload => fileUpload.isComplete)) {
      this.onAllCompleteCb && this.onAllCompleteCb({ files: this.fileUploads });

      // Resolve the completed promise (if set) and return the uploaded files list
      this.completedPromise &&
        this.completedPromise(
          this.fileUploads.map(fileUpload => fileUpload.uploadedFile)
        );
    }
  }

  /**
   * Returns a native JS object that is easier to work with than the File objects (no weird behavior of missing properties, easily printable, etc.)
   * @param file
   * @returns {{size, name, progress, location, blobUrl: *, id, type}}
   */
  static wrapFile(file) {
    return {
      id: file.id,
      uid: file.uid || file.id,
      name: file.name || file.filename,
      filename: file.filename || file.name,
      filepath: file.filepath,
      size: file.size,
      type: file.type || file.mime,
      mime: file.mime || file.type,
      meta: file.meta,
      transcodes: file.transcodes,
      progress: file.progress || file.percentage / 100,
      percentage: file.percentage || 100 * file.progress,
      location: file.location,
      blobUrl: file.blobUrl,
      url: file.blobUrl || file.url,
      status: file.progress < 1 ? 'uploading' : 'uploaded'
    };
  }

  /**
   * Registers all the callbacks requested for the XHR / post-processing of file uploads
   */
  setXhrCallbacks() {
    // Set the error callbacks
    for (let fileUpload of this.fileUploads) {
      fileUpload.xhr.addEventListener(
        'error',
        e => this.errorHandler(e, fileUpload.file),
        false
      );
    }

    // Set the progress callbacks
    if (this.onProgressCb) {
      for (let fileUpload of this.fileUploads) {
        fileUpload.xhr.upload.addEventListener(
          'progress',
          e => {
            // Max of 95%, so we can indicate we are completing the signed URL process
            let progress = Math.min(0.95, e.loaded / e.total);
            this.fireProgressCallback(fileUpload, progress);
          },
          false
        );
      }
    }

    // Set the load callbacks which registers the Complete / All Complete callbacks and handles non-xhr related errors
    for (let fileUpload of this.fileUploads) {
      fileUpload.xhr.addEventListener(
        'load',
        async e => {
          try {
            // First complete the presigned upload to get the updated file resource data
            const uploadedFile = await this.completePresignedUpload(fileUpload);
            fileUpload.uploadedFile = uploadedFile;

            // Fire the file complete callbacks
            this.fireCompleteCallback(fileUpload, uploadedFile);
            this.checkAllComplete();
          } catch (error) {
            this.errorHandler(e, fileUpload.file, error);
          }
        },
        false
      );
    }
  }

  /**
   * Mark the presigned upload as completed and return the file resource from the platform server
   * @param fileUpload
   * @returns {Promise<void>}
   */
  async completePresignedUpload(fileUpload) {
    // Show 95% as the last 5% will be to complete the presigned upload
    this.fireProgressCallback(fileUpload, 0.95);

    // Let the platform know the presigned upload is complete
    const url = `${window._env.API_URL}/file/presigned-upload-url-completed/${fileUpload.file.resource_id}`;
    return await fetch(url, { method: 'POST' }).then(r => r.json());
  }

  /**
   * Start uploading all files
   */
  async upload() {
    for (let fileUpload of this.fileUploads) {
      const mimeType = fileUpload.file.mimeType || fileUpload.file.type;
      const name = fileUpload.file.name.replace(/[\s#]/g, '-');
      const presignedUrl = `${window._env.API_URL}/file/presigned-upload-url?path=${this.directory}&name=${name}&mime=${mimeType}`;

      // Fetch presigned upload URL
      const fileResource = await fetch(presignedUrl).then(r => r.json());

      if (!fileResource.url) {
        Vue.prototype.$message.error(
          'Could not fetch presigned upload URL for file ' +
            fileUpload.file.name
        );
        continue;
      }

      const isS3Upload = !fileResource.url.match(
        'upload-presigned-url-contents'
      );

      // We need the file resource ID to complete the presigned upload
      fileUpload.file.resource_id = fileResource.id;

      // Prepare XHR request
      const xhr = new XMLHttpRequest();

      // The XHR request is different based on weather we're sending to S3 or the platform server
      if (isS3Upload) {
        xhr.open('PUT', fileResource.url);
        xhr.setRequestHeader('Content-Type', mimeType);
        fileUpload.body = fileUpload.file;
      } else {
        xhr.open('POST', fileResource.url);
        fileUpload.body = fileUpload.formData;
      }

      fileUpload.xhr = xhr;
    }

    // Set all the callbacks on the XHR requests
    this.setXhrCallbacks();

    return await this.sendAndReturnFiles();
  }

  /**
   * Send all the XHR file uploads and return a promise that will be resolved w/ the completed files when all files have been uploaded
   * @returns {Promise<unknown>}
   */
  sendAndReturnFiles() {
    return new Promise((resolve, reject) => {
      // This promise will be resolved in the checkAllComplete method
      this.completedPromise = resolve;

      // Send all the XHR file uploads
      for (let fileUpload of this.fileUploads) {
        fileUpload.xhr.send(fileUpload.body);
      }
    });
  }
}
